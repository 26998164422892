<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({ preview: 'SlicesMusicMakingWavesMmwThreeImageDivider.jpg', description: 'Music Making Waves Three Image Divider', fields: { image1: { type: 'media', label: 'Image 1', breakpoints: { lg: { width: 500, height: 500 }, sm: { width: 300, height: 300 } } }, image2: { type: 'media', label: 'Image 2', breakpoints: { lg: { width: 500, height: 500 }, sm: { width: 300, height: 300 } } }, image3: { type: 'media', label: 'Image 3', breakpoints: { lg: { width: 500, height: 500 }, sm: { width: 300, height: 300 } } } }, name: { label: 'Mmw Three Image Divider', group: 'Music Making Waves' }, templates: [{ label: 'Mmw Three Image Divider', fields: { image1: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/MMW\/Janet%20Jackson\/dc1-8811-v2.jpg' }, image2: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/MMW\/istock-484259118.jpg' }, image3: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/MMW\/Janet%20Jackson\/dc1-8972.jpg' } } }] })
  },
}
</script>

<template>
  <div class="grid grid-cols-3">
    <div>
      <VoixMedia :field="fields.image1" class="w-full h-full object-cover" />
    </div>
    <div>
      <VoixMedia :field="fields.image2" class="w-full h-full object-cover" />
    </div>
    <div>
      <VoixMedia :field="fields.image3" class="w-full h-full object-cover" />
    </div>
  </div>
</template>
