<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({ preview: 'SlicesOnetrustPrivacyPolicy.jpg', description: 'One Trust Privacy Policy', fields: [], name: { label: 'Privacy Policy', group: 'Onetrust' }, templates: [{ label: 'Privacy Policy', fields: [] }] })
  },
}
</script>

<template>
  <div class="container font-sans2 py-16">
    <div class="w-full p-12 bg-white">
      <!-- Container in which the privacy notice will be rendered -->
      <div id="otnotice-052bcd62-6e08-47c9-9723-695a102136ae" class="otnotice" />
    </div>
  </div>
</template>
