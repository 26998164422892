<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({ preview: 'SlicesMenusMenu.jpg', description: 'Menu generated from Single Platform.', fields: { locationId: { type: 'text', label: 'Single Platform: Location ID', instructions: '"Look for data-location=\\"XXXXXXX\\" in the \\"Generated Code\\" section of Single Platform. Also, due to the nature of single platform this will not load until you are logged out."' } }, name: { label: 'Menu', group: 'Menus' }, templates: [{ label: 'Menu', fields: { locationId: { value: 'pirates-republic-taproom' } } }] })
  },
}
</script>

<template>
  <div class="container">
    <div id="singleplatform-menu" />
    <single-platform-menu :devise="devise" />
  </div>
</template>
