<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showFullList: false,
    }
  },
  mounted() {
    defineSlice({ preview: 'SlicesGlueHotelFeaturesDailyResortFee.jpg', description: 'Explaination of the Daily Resort Fee', name: { label: 'Daily Resort Fee', group: 'Glue' }, templates: [{ label: 'Daily Resort Fee', fields: [] }] })
  },
}
</script>

<template>
  <div class="bg-orange-50 py-24">
    <div class="small-container text-center">
      <div
        class="uppercase text-4xl tracking-wider text-gray-900 font-sans font-light leading-tight mb-4 md:mb-0"
      >
        All Access Paradise
      </div>
      <div
        class="font-sans md:font-sans2 leading-loose font-normal md:font-thin text-18 uppercase md:normal-case text-gray-500 md:text-gray-800 leading-tight mb-12 px-8 md:px-16 md:px-0"
      >
        The daily resort fee of $59.00 + 10% VAT* at The Coral, $65.00 + 10% VAT at The Royal, or $70.00 + 10% VAT* for The Cove & The Reef includes:
      </div>

      <div class="flex flex-wrap max-w-2xl mx-auto text-red-500 justify-center">
        <GlueHotelFeaturesFeature class="w-1/2 md:w-[200px] pb-8 mb-8 md:mb-0" title-width="small">
          <GlueSvgsFeaturesWaterPark style="width: 40px; height: 40px" />
          <template #title>
            Water Park
          </template>
          <template #subtitle>
            Daily Access
          </template>
        </GlueHotelFeaturesFeature>
        <GlueHotelFeaturesFeature class="w-1/2 md:w-[200px] pb-8 mb-8 md:mb-0">
          <GlueSvgsFeaturesShuttle style="width: 40px; height: 40px" />
          <template #title>
            Shuttle Service
          </template>
          <template #subtitle>
            Between Hotels
          </template>
        </GlueHotelFeaturesFeature>
        <GlueHotelFeaturesFeature class="w-1/2 md:w-[200px] pb-8 mb-8 md:mb-0">
          <GlueSvgsFeaturesCoffee style="width: 40px; height: 40px" />
          <template #title>
            Coffee &amp; Tea
          </template>
          <template #subtitle>
            In Every Room
          </template>
        </GlueHotelFeaturesFeature>
        <GlueHotelFeaturesFeature class="w-1/2 md:w-[200px] pb-8 mb-8 md:mb-0" title-width="large">
          <GlueSvgsFeaturesWaterBottle style="width: 40px; height: 40px" />
          <template #title>
            Reusable Water Bottles
          </template>
          <template #subtitle>
            Complimentary
          </template>
        </GlueHotelFeaturesFeature>
        <GlueHotelFeaturesFeature class="w-1/2 md:w-[200px] pb-8 mb-8 md:mb-0">
          <GlueSvgsFeaturesFitness style="width: 40px; height: 40px" />
          <template #title>
            Fitness Center
          </template>
          <template #subtitle>
            2 Guests Per Day
          </template>
        </GlueHotelFeaturesFeature>
        <GlueHotelFeaturesFeature class="w-1/2 md:w-[200px] pb-8 mb-8 md:mb-0">
          <GlueSvgsFeaturesWifi style="width: 40px; height: 40px" strokewidth="5" />
          <template #title>
            WIFI All Access
          </template>
          <template #subtitle>
            In Rooms &amp; Lobbies
          </template>
        </GlueHotelFeaturesFeature>
      </div>
    </div>
  </div>
</template>
