<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({ preview: 'SlicesDocumentationSlicePreviews.jpg', slots: [{ name: 'default' }], description: 'SlicePreview', name: { label: 'Slice Previews', group: 'Documentation' }, templates: [{ label: 'Slice Previews', fields: [] }] })
  },
}
</script>

<template>
  <div>
    <slice-preview :devise="devise">
      <slot />
    </slice-preview>
  </div>
</template>
