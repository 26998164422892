<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({ preview: 'SlicesMusicMakingWavesMmwExcerptIntroduction.jpg', description: 'Music Making Waves Excerpt Introduction', fields: { excerpt: { type: 'textarea', label: 'Excerpt' }, introduction: { type: 'wysiwyg', label: 'Introduction' } }, name: { label: 'Mmw Excerpt Introduction', group: 'Music Making Waves' }, templates: [{ label: 'Mmw Excerpt Introduction', fields: { excerpt: { value: 'Music lovers far and wide come to join in the fun pre-concert festivities and cool vibes of atlantis concert village.' }, introduction: { value: '<p>We combine some of the most talented local artists, along with an impressive selection of art installations, food trucks and entertainment to make the pre-show experience an event unto itself.<br><br>This fun family destination embraces the casual island lifestyle and Bahamian hospitality with pop-up lounges, dancing and lawn games while allowing families, friends and music lovers to connect.<\/p>' } } }] })
  },
}
</script>

<template>
  <div class="grid lg:grid-cols-2 gap-8 text-black px-4 lg:px-16">
    <div class="text-mmw-orange uppercase font-mmw-headline text-[28px] lg:text-[48px] leading-none">
      {{ fields.excerpt.value }}
    </div>
    <div class="font-mmw prose" v-html="fields.introduction.value" />
  </div>
</template>
