<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({ preview: 'SlicesBookingBookNowBar.jpg', description: 'Horizontal, partially-inline booking bar', name: { label: 'Book Now Bar', group: 'Booking' }, templates: [{ label: 'Book Now Bar', fields: [] }] })
  },
}
</script>

<template>
  <div class="container mb-24 relative z-20">
    <div class="flex justify-center">
      <div class="bg-white shadow w-full md:w-auto md:mx-24">
        <book-now :inline="true" :horizontal="true" />
      </div>
    </div>
  </div>
</template>
