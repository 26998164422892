<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({ preview: 'SlicesDocumentationSlicePreviewCategory.jpg', slots: [{ name: 'default' }], description: 'Categories for the ', fields: { categoryName: { type: 'text', label: 'Category Name' } }, name: { label: 'Slice Preview Category', group: 'Documentation' }, templates: [{ label: 'Slice Preview Category', fields: { categoryName: { value: 'Heroes' } } }] })
  },
}
</script>

<template>
  <div>
    <slot />
  </div>
</template>
