<script>
import { resolveFieldsArray } from '@voix/composables/useField'
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      swiper: null,
      currentSlide: 0,
      showHotelInfo: true,
    }
  },
  computed: {
    hotelSlices() {
      if (this.slice.elements[0])
        return this.slice.elements[0].elements

      return []
    },

    cardStyles() {
      const styles = {}
      if (this.$voix.breakpoint.value === 'lg' || this.$voix.breakpoint.isGreaterThan('lg')) {
        styles.height = '250px'
        styles.width = '976px'
      }
      else if (this.$voix.breakpoint.value === 'md') {
        styles.height = 'auto'
        styles.width = '100%'
      }
      else {
        styles.height = 'auto'
      }

      return styles
    },

    currentElement() {
      return this.$refs[`hotelSlide-${this.currentSlide}`]
    },

    currentHotel() {
      return this.hotelSlices[this.currentSlide]
    },

    currentHotelFields() {
      return resolveFieldsArray(this.currentHotel)
    },

    hotelImages() {
      const images = []
      this.slice.elements[0].elements.map((slice) => {
        if (slice.logo && slice.magazineImage) {
          images.push(slice.logo.url)
          images.push(slice.magazineImage.url)
        }
        return slice
      })
      return images
    },
  },
  mounted() {
    defineSlice({
      preview: 'SlicesGlueHotelsHotelIndex.jpg',
      description: 'List of hotels',
      fields: {
        displayTitleCard: { type: 'checkbox', label: 'Display Title Card?', default: true },
        titleCardPosition: { type: 'select', label: 'Title Card Position', options: { 'top-left': 'Top Left', 'top-right': 'Top Right', 'bottom-left': 'Bottom Left', 'bottom-right': 'Bottom Right' } },
        subTitle: { type: 'text', label: 'Sub Title' },
        title: { type: 'text', label: 'Title' },
        description: { type: 'textarea', label: 'Description' },
        wide: { type: 'checkbox', label: 'Wide?' },
        slider: { type: 'checkbox', label: 'Slider?', default: true },
        hotelCardPosition: { type: 'select', label: 'Hotel Card Position', options: { 'top-left': 'Top Left', 'top-right': 'Top Right', 'bottom-left': 'Bottom Left', 'bottom-right': 'Bottom Right' } },
      },
      name: { label: 'Hotel Index', group: 'Glue' },
      templates: [{
        label: 'Hotel Index',
        fields: {
          hotelCardPosition: { value: 'top-left' },
          titleCardPosition: { value: 'bottom-right' },
        },
      }],
    })
    this.preloadImages()
  },

  methods: {
    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    goToSlide(index) {
      this.swiper.slideTo(index)
    },

    onSwiper(swiperInstance) {
      this.swiper = swiperInstance
    },

    setCurrentSlide() {
      if (this.swiper) {
        let slideIndex = this.swiper.realIndex
        if (slideIndex >= this.hotelSlices.length)
          slideIndex = 0
        else if (slideIndex < 0)
          slideIndex = this.hotelSlices.length - 1

        this.showHotelInfo = false
        setTimeout(() => {
          this.currentSlide = slideIndex
          this.showHotelInfo = true
        }, 500)
      }
    },

    preloadImages() {
      return new Promise((resolve, reject) => {
        (function loadEach(images, index) {
          if (index < images.length && images[index]) {
            const img = new Image()
            img.src = images[index]
            images[index] = img
            images[index].onload = function () {
              loadEach(images, ++index)
            }
            images[index].onerror = err => reject(err)
          }
          else {
            resolve(images)
          }
        })(this.hotelImages, 0)
      })
    },
  },

}
</script>

<template>
  <div class="lg:pt-48 lg:mt-48">
    <div
      id="hotel-index" class="relative" :class="{
        'lg:pt-48':
          fields.hotelCardPosition.value === 'top-left'
          || fields.hotelCardPosition.value === 'top-right',
      }"
    >
      <div
        v-if="fields.displayTitleCard.value"
        class="absolute w-full bg-white py-10 px-8 md:px-12 lg:w-2/5 -mt-64 lg:-mt-20 z-10" :class="{
          'lg:top-0 lg:left-0': fields.titleCardPosition.value === 'top-left',
          'lg:top-0 lg:right-0': fields.titleCardPosition.value === 'top-right',
          'lg:bottom-0 lg:left-0':
            fields.titleCardPosition.value === 'bottom-left',
          'lg:bottom-0 lg:right-0':
            fields.titleCardPosition.value === 'bottom-right',
        }" style="max-width: 493px"
      >
        <div class="font-serif text-3xl tracking-wider italic text-red-500">
          {{ fields.subTitle.value }}
        </div>
        <div class="headline-2 text-zinc-700 mb-4">
          {{ fields.title.value }}
        </div>
        <div class="body text-zinc-600 w-4/5">
          {{ fields.description.value }}
        </div>
      </div>
      <!-- hotels -->
      <div
        v-if="fields.slider.value
          && slice.elements[0]
          && slice.elements[0].elements.length
        " class="pb-16 lg:pb-50 overflow-hidden"
      >
        <div class="relative">
          <Swiper
            ref="mySwiper" slides-per-view="auto" :space-between="0" :loop="hotelSlices.length > 1" :centered-slides="true"
            @active-index-change="setCurrentSlide"
            @swiper="onSwiper"
          >
            <SwiperSlide v-for="(element, key) in hotelSlices" :key="key">
              <VoixSlice :ref="`hotelSlide-${key}`" :slice="element" :slice-index="key" class="w-full" :slider="true" />
            </SwiperSlide>
          </Swiper>
          <div
            v-if="hotelSlices.length > 1"
            class="absolute z-10 inset-0 pointer-events-none flex justify-between items-center px-8"
          >
            <button
              class="pointer-events-auto slider-button mr-4 mt-4 text-orange-900 hover:text-white bg-orange-50"
              @click="prev"
            >
              <svg class="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <button
              class="pointer-events-auto slider-button mt-4 text-orange-900 hover:text-white bg-orange-50"
              :data-testid="swiper !== null ? 'glue-hotel-index-next-button' : ''"
              @click="next"
            >
              <svg
                class="w-7 h-7 transform rotate-180" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <ClientOnly>
          <div
            class="w-full lg:mb-16 lg:absolute z-10 bg-white py-10 px-6 lg:px-12 lg:w-2/5 flex " :class="{
              'lg:top-0 lg:left-0': fields.hotelCardPosition.value === 'top-left',
              'lg:top-0 lg:right-0':
                fields.hotelCardPosition.value === 'top-right',
              'lg:bottom-0 lg:left-0':
                fields.hotelCardPosition.value === 'bottom-left',
              'right-0 bottom-0':
                fields.hotelCardPosition.value === 'bottom-right',
            }" :style="cardStyles"
          >
            <transition
              enter-active-class="transition-opacity duration-500" enter-class="opacity-0"
              enter-to-class="opacity-100" leave-active-class="transition-opacity duration-200" leave-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div v-show="showHotelInfo" class="flex flex-col lg:flex-row justify-between w-full">
                <div
                  v-if="currentHotel"
                  class="pb-8 lg:pb-0 lg:pl-6 lg:pr-12 border-b lg:border-b-0 lg:border-r border-gray-400 flex flex-col lg:flex-row items-center lg:mr-12 mb-4 lg:mb-0"
                >
                  <div>
                    <a :href="currentHotelFields.link.value">
                      <VoixMedia :field="currentHotelFields.logo" style="width: 200px" />
                    </a>
                  </div>
                </div>
                <div v-if="currentHotel" class="flex flex-col lg:flex-row py-4">
                  <div class="w-full justify-between flex flex-col md:flex-row">
                    <div class="w-full lg:w-3/4 lg:pr-8">
                      <div data-testid="glue-hotels-index-title" class="headline-2 text-zinc-700 mb-4">
                        {{ currentHotelFields.magazineTitle.value }}
                      </div>
                      <div
                        v-if="$voix.breakpoint !== 'default' && $voix.breakpoint !== 'sm'"
                        class="body text-zinc-600 mb-12 md:mb-0"
                      >
                        {{ currentHotelFields.magazineDescription.value }}
                      </div>
                    </div>
                    <div class="w-1/2 md:w-1/3 text-right flex flex-col justify-between">
                      <div class="text-right mb-6" />
                      <a
                        v-if="$voix.breakpoint !== 'default' && $voix.breakpoint !== 'sm' && currentHotelFields.link.value" :href="currentHotelFields.link.value"
                        class="glue-btn btn-ghost border-zinc-600 text-zinc-700 uppercase btn-ghost body-11 tracking-widest py-3 px-6"
                      >
                        Explore {{ currentHotelFields.title.value }}
                      </a>
                    </div>
                  </div>
                  <template v-if="$voix.breakpoint === 'default' || $voix.breakpoint === 'sm'">
                    <div class="body text-zinc-600 mb-12 md:mb-0">
                      {{ currentHotelFields.magazineDescription.value }}
                    </div>
                    <div class="w-full lg:w-2/5">
                      <div class="flex justify-center lg:justify-end">
                        <a
                          :href="currentHotelFields.link.value"
                          class="glue-btn btn-ghost border-zinc-600 text-xs tracking-widest p-3 pt-5 px-12 text-zinc-700"
                        >
                          Explore {{ currentHotelFields.title.value }}
                        </a>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </transition>
          </div>
        </ClientOnly>
      </div>
      <div v-else class="border-white xl:mb-20">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.swiper-slide {
  width: 100%;
}
</style>
