<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      events: [],
    }
  },
  async mounted() {
    defineSlice({ description: 'Venue Featured Events', fields: { title: { type: 'text', label: 'Title', default: '"Featured Events"' }, link: { type: 'link', label: 'Link', enabled: true }, venue: { type: 'select', label: 'Venue', options: { Aura: 'Aura', AKA: 'AKA', Theater: 'Theater', Crush: 'Crush', BarSol: 'BarSol', JokersWild: 'Jokers Wild', Outdoors: 'Outdoors' } } }, name: { label: 'Venue Featured Events', group: 'Glue' }, templates: [{ label: 'Venue Featured Events', fields: { link: { value: { text: 'View All', target: '_self', relationship: null, href: '\/new-page' } }, venue: { value: 'JokersWild' } } }] })

    const { data } = await $voixFetch(`/api/devise/atlantis-events/in-venue?venue=${this.fields.venue.value}`)
    this.events = data
  },
}
</script>

<template>
  <div class="py-16">
    <div v-show="events.length > 0" class="container mx-auto">
      <div class="flex items-center justify-between">
        <div class="headline-2">
          {{ fields.title.value }}
        </div>
        <VoixLink
          :field="fields.link"
          class="inline-block text-center border border-zinc-500 text-zinc-700 font-bold px-8 py-5 leading-snug uppercase focus:outline-none font-sans text-2xs min-w-[180px]"
        />
      </div>
      <div class="mt-6 flex space-x-8">
        <EventsModalEventCard
          v-for="event in events"
          :key="event.id"
          :event="event"
          :siblings="events"
          class="max-w-[400px]"
        />
      </div>
    </div>
  </div>
</template>
