<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      preview: 'SlicesCopyGeneralCopy.jpg',
      description: 'General content',
      fields: { copy: { type: 'wysiwyg', label: 'Copy' } },
      name: { label: 'General Copy', group: 'Copy' },
      templates: [{
        label: 'General Copy',
        fields: { copy: { value: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae orci id nisi ultricies vestibulum. Cras commodo molestie blandit. Donec eget finibus lacus. Sed ex dolor, volutpat id molestie sit amet, mollis id nibh. Donec condimentum pretium lorem. Praesent eget nibh gravida est dictum faucibus nec et eros. Vivamus vitae magna eget elit venenatis ullamcorper consequat et erat. Vivamus vestibulum ultricies molestie. Fusce rutrum ornare nunc, vel cursus augue. Pellentesque quis cursus turpis. Morbi ut nisi non lorem hendrerit varius. Maecenas hendrerit, mi sit amet fringilla ultricies, leo lacus tristique augue, in ullamcorper orci tellus eget justo.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae orci id nisi ultricies vestibulum. Cras commodo molestie blandit. Donec eget finibus lacus. Sed ex dolor, volutpat id molestie sit amet, mollis id nibh. Donec condimentum pretium lorem. Praesent eget nibh gravida est dictum faucibus nec et eros. Vivamus vitae magna eget elit venenatis ullamcorper consequat et erat. Vivamus vestibulum ultricies molestie. Fusce rutrum ornare nunc, vel cursus augue. Pellentesque quis cursus turpis. Morbi ut nisi non lorem hendrerit varius. Maecenas hendrerit, mi sit amet fringilla ultricies, leo lacus tristique augue, in ullamcorper orci tellus eget justo.</p>' } },
      }],
    })
  },
}
</script>

<template>
  <div class="container prose">
    <div v-html="fields.copy.value" />
  </div>
</template>
