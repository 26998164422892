<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({ preview: 'SlicesMusicMakingWavesMmwFeatures.jpg', description: 'Music Making Waves Features', fields: { title: { type: 'text', label: 'Title' }, description: { type: 'textarea', label: 'Description' }, color: { type: 'select', label: 'Color', options: { orange: 'Orange', teal: 'Teal', blue: 'Blue', black: 'Black' } }, column1Title: { type: 'text', label: 'Column 1 Title' }, column1Description: { type: 'wysiwyg', label: 'Column 1 Description' }, column2Title: { type: 'text', label: 'Column 2 Title' }, column2Description: { type: 'wysiwyg', label: 'Column 2 Description' }, link: { type: 'link', label: 'Link', enabled: false } }, name: { label: 'Mmw Features', group: 'Music Making Waves' }, templates: [{ label: 'Mmw Features', fields: { column1Description: { value: '<p>\n<font color="#FFFFFF ">Beer Garden<br>Tropical Bar<br>Food Trucks<br><br><br><\/p>' }, column1Title: { value: 'food & drinks' }, column2Description: { value: '<p>\n<font color="#FFFFFF ">Live DJ<br>Body Art<br>Crafts Market<\/p>' }, column2Title: { value: 'entertainment' }, description: { value: 'Start the festivities in the concert village where you can enjoy food, beverages, and fun!' }, link: { value: { text: 'LEARN MORE', target: '_self', relationship: null, href: '\/new-page' } }, title: { value: 'concert village' }, color: { value: 'teal' } } }] })
  },
}
</script>

<template>
  <div class="big-container mx-auto py-24">
    <div class="w-full grid gap-12 lg:grid-cols-12 font-mmw px-6 lg:px-16">
      <div class="col-span-6">
        <div class="font-mmw-headline text-[64px] font-bold uppercase leading-[1em]">
          {{ fields.title.value }}
        </div>
        <div class="mt-4">
          {{ fields.description.value }}
        </div>
        <a
          v-if="fields.link.enabled" :href="fields.link.value?.href" :target="fields.link.value?.target"
          class="mt-8 inline-block font-mmw font-bold  rounded-tl-xl rounded-br-xl py-4 px-6" :class="{
            'bg-mmw-teal text-white': fields.color.value == 'teal',
            'bg-mmw-orange text-black': fields.color.value == 'orange',
            'bg-mmw-blue text-mmw-teal': fields.color.value == 'blue',
            'bg-black text-white': fields.color.value == 'black',
          }"
        >
          {{ fields.link.value?.text }}
        </a>
      </div>
      <div class="col-span-3">
        <div class="font-mmw-headline text-xl font-bold uppercase">
          {{ fields.column1Title.value }}
        </div>
        <div class="mt-4 prose prose-lg" v-html="fields.column1Description.value" />
      </div>
      <div class="col-span-3">
        <div class="font-mmw-headline text-xl font-bold uppercase">
          {{ fields.column2Title.value }}
        </div>
        <div class="mt-4  prose prose-lg" v-html="fields.column2Description.value" />
      </div>
    </div>
  </div>
</template>
