<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      description: 'Information Block about a restaurants hours, menu, etc',
      fields: {
        ageRestrictions: { value: 'Age Restrictions' },
        hours: { type: 'wysiwyg', label: 'Operating Hours', enabled: true },
        menusHtml: { type: 'wysiwyg', label: 'Menus' },
        reservationDetails: { type: 'wysiwyg', label: 'Reservation Details' },
      },
      name: { label: 'Restaurant Information' },
      templates: [{
        label: 'Restaurant Information',
        fields: {
          ageRestrictions: { value: 'Age Restrictions' },
          hours: { value: 'Operating Hours', enabled: true },
          menusHtml: { value: 'Menus' },
          reservationDetails: { value: 'Reservation Details' },
        },
      }],
    })
  },
}
</script>

<template>
  <div>
    <div class="container my-24 text-center">
      <div class="flex items-start justify-between shadow bg-white p-8">
        <div v-if="fields.hours.enabled" class="flex flex-col items-center w-1/4 p-2">
          <div class="mb-2 room-icon operating-hours">
&nbsp;
          </div>
          <h6 class="uppercase text-black mb-4" />
          <p class="text-sm leading-loose" v-html="fields.hours.value" />
        </div>
        <div v-if="fields.ageRestrictions.enabled" class="flex flex-col items-center w-1/4 p-2">
          <div class="mb-2 room-icon age-restrictions">
&nbsp;
          </div>
          <h6 class="uppercase text-black mb-4" />
          <p class="text-sm leading-loose" v-html="fields.ageRestrictions.value" />
        </div>
        <div v-if="fields.reservationDetails.enabled" class="flex flex-col items-center w-1/4 p-2">
          <div class="mb-2 room-icon reservations">
&nbsp;
          </div>
          <h6 class="uppercase text-black mb-4" />
          <p class="text-sm leading-loose" v-html="fields.reservationDetails.value" />
        </div>
        <div v-if="fields.menusHtml.enabled" class="flex flex-col items-center w-1/4 p-2">
          <div class="mb-2 room-icon menus">
&nbsp;
          </div>
          <h6 class="uppercase text-black mb-4" />
          <p class="text-sm leading-loose" v-html="fields.menusHtml.value" />
        </div>
      </div>
    </div>
  </div>
</template>
