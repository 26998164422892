<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({ preview: 'SlicesWffIntroduction.jpg', description: 'Introduction', fields: { intro: { type: 'wysiwyg', label: 'Intro' }, copy: { type: 'wysiwyg', label: 'Copy' } }, name: { label: 'Introduction', group: 'Wff' }, templates: [{ label: 'Introduction', fields: { copy: { value: '<p>That\u2019s a big question with a lot of answers. There are so many factors that go into curating these types of menus. We had to create two separate yet related menus for the Table Takeover at Caf\u00E9 Martinique and then for the [intimate] Beach Dinner. To start, I tried to work within the framework provided by Caf\u00E9 Martinique insofar as keeping the general vibe of the menu in-line with what the guests would be interested in and find exciting.<\/p><p>From there, I took seasonality and execution into account. With a menu this extensive, it\u2019s important to make sure that it\u2019s actually doable in a way that makes sense both for the staff and the guests. We worked really hard to ensure that every dish that left the kitchen was to the standards that I hold for myself and that Caf\u00E9 Martinique holds for their space.<\/p><p>Finally, I thought about what I would want to eat if I were a guest, and then brainstormed until we found the right balance.<\/p><p>&nbsp;<\/p>' }, intro: { value: '<p><em>How did you approach the process of curating the menu for the Table Takeovers event and what factors did you take into consideration?<\/em><\/p>' } } }] })
  },
}
</script>

<template>
  <div class="flex justify-center p-8">
    <div class="container grid grid-cols-1 md:grid-cols-12 gap-12">
      <div class=" md:col-span-4 md:col-start-2 prose font-wff text-xl" v-html="fields.intro.value" />
      <div class="md:col-span-6 font-sans2 wysiwyg paragraph-spacer" v-html="fields.copy.value" />
    </div>
  </div>
</template>
