<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
    }
  },

  mounted() {
    defineSlice({
      name: { label: 'Mosaic Side By Side Cards', group: 'Cards', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesCardsMosaicSideBySideCards.jpeg',
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: {
            default: { width: 800, height: 800 },
          },
        },
        imageOnLeft: {
          type: 'checkbox',
          label: 'Image on Left',
          default: true,
        },
        superscript: {
          type: 'text',
          label: 'Superscript',
        },
        title: {
          type: 'text',
          label: 'Title',
          editorLabel: true,
        },
        copy: { type: 'wysiwyg', label: 'Copy' },
        link: { type: 'link', label: 'Link', enabled: true },
      },

      templates: [{
        label: 'Card',
        fields: {
          image: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/legacy/atlantis/Casino/Overview/Overview_SpecialOffers_532x309.jpg' },
          superscript: { value: 'CONDE NAST' },
          title: { value: 'YOUR MOMENTS MATTER' },
          copy: { value: 'Lorem Ipsum' },
          link: { value: { text: 'Learn More', target: '_self', relationship: null, href: '/new-page' } },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="flex flex-col space-y-20 p-8 lg:p-24">
    <div
      class="w-full xl:w-3/4 mx-auto flex flex-col items-end" :class="{
        'lg:flex-row-reverse': !fields.imageOnLeft.value,
        'lg:flex-row': fields.imageOnLeft.value,
      }"
    >
      <div class="relative z-10 aspect-square w-full lg:-translate-y-4">
        <VoixMedia :field="fields.image" :alt="fields.title.value" class="w-full h-full object-cover" />
      </div>
      <div
        class="px-24 py-24 w-full lg:max-w-lg bg-glueblue-600 text-white" :class="{
          'lg:-translate-x-12': fields.imageOnLeft.value,
          'lg:translate-x-12': !fields.imageOnLeft.value,
        }"
      >
        <div class="text-3xl leading-[1em] font-sans2 font-light uppercase">
          <div v-if="fields.superscript.value" class="subtitle text-sans2 text-xs">
            {{ fields.superscript.value }}
          </div>
          {{ fields.title.value }}
        </div>
        <VoixWysiwyg :field="fields.copy" />
        <VoixLink :field="fields.link" class=" mt-8 btn btn-ghost btn-xs" />
      </div>
    </div>
  </div>
</template>
