<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      canSee: true,
    }
  },
  mounted() {
    defineSlice({
      description: 'Introduction with a booking button',
      fields: {
        title: { label: 'Title', type: 'text' },
        copy: { label: 'Copy', type: 'wysiwyg' },
      },
      name: { label: 'Intro And Book', group: 'Glue' },
      templates: [{
        label: 'Intro And Book',
        fields: { copy: { value: '<p>Stay minutes away from all the excitement of Summer Solstice when you book a suite at The Cove.<\/p>' }, title: { value: 'Book Your stay at the Cove' } },
      }],
    })
  },
  methods: {
    isVisible() {
      this.canSee = true
    },

  },
}
</script>

<template>
  <div class="container mx-auto py-24 flex justify-between flex-col lg:flex-row text-zinc-700 font-sans2">
    <div
      class="lg:w-3/4 opacity-70 px-6 lg:px-24  transition-all duration-[1.5s]"
      :class="{
        '-translate-x-64 opacity-0': !canSee,
        'translate-x-0 opacity-100': canSee,
      }"
    >
      <div class="uppercase text-2xl tracking-wider font-light font-sans">
        {{ fields.title.value }}
      </div>
      <div class="mt-4 font-sans2 font-light text-zinc-700" v-html="fields.copy.value" />
    </div>
    <div
      class="mt-4 flex flex-col items-center lg:w-1/4 transition-all duration-[1.5s] delay-1000" :class="{
        'opacity-0': !canSee,
        'opacity-100': canSee,
      }"
    >
      <glue-book-now
        location="navigation"
        disableable
      >
        <template>
          <button class="glue-btn text-glueblue-600 border-glueblue-600 border">
            Book Now
          </button>
        </template>
      </glue-book-now>
    </div>
  </div>
</template>
