<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      preview: 'SlicesCareersTakeoverWrapper.jpg',
      slots: [{ name: 'default' }],
      description: 'Takeover Wrapper',
      fields: {
        bottomImage: {
          label: 'Background Image',
          type: 'media',
          breakpoints: {
            default: { width: 1600 },
          },
        },
        backgroundImagePosition: { label: 'Background Image Position', type: 'select', options: { 'object-top': 'Top', 'object-left-top': 'Top Left', 'object-right-top': 'Top Right', 'object-center': 'Center', 'object-left': 'Left', 'object-right': 'Right', 'object-bottom': 'Bottom', 'object-left-bottom': 'Bottom Left', 'object-right-bottom': 'Bottom Right' } },
      },
      name: {
        label: 'Takeover Wrapper',
        group: 'Careers',
      },
      templates: [{
        label: 'Takeover Wrapper',
        fields: { bottomImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Careers\/homepage-bk-rv.jpg' }, backgroundImagePosition: { value: 'object-bottom' } },
      }],
    })
  },
}
</script>

<template>
  <div class="relative">
    <div class="flex justify-center pb-12">
      <div class="relative z-10 container flex flex-col items-center">
        <slot />
      </div>

      <div class="absolute inset-0 z-0">
        <VoixMedia :field="fields.bottomImage" class="object-cover w-full h-full" :class="[fields.backgroundImagePosition.value]" />
      </div>
    </div>
  </div>
</template>
