<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  mounted() {
    defineSlice({
      name: { label: 'Gallery Container', group: 'Gallery', layouts: ['*', 'Atlantisbahamas', 'Reef', 'Barbie'] },
      slots: [{ name: 'default', allowedElements: ['SlicesGalleryGalleryImage'] }],
      description: 'Container for gallery slices to make a grid gallery',
      preview: 'SlicesGalleryGalleryContainer.jpg',
      fields: {
        units: {
          type: 'select',
          label: 'Gallery Units',
          options: {
            thirds: 'Thirds',
            fourths: 'Fourths',
          },
          default: 'thirds',
        },
        link: { type: 'link', label: 'Link', enabled: false },
      },
      templates: [{
        label: 'Gallery Container',
        fields: {
          units: { value: 'thirds' },

        },
      }],
    })
  },
}
</script>

<template>
  <div class="text-center mb-24">
    <div class="clearfix photo-grid md:grid grid-cols-12">
      <VoixSlice v-for="element in slice.elements" :key="element.id" :slice="element" />
    </div>

    <a
      v-if="fields.link.enabled" class="btn btn-2xs text-white bg-black relative z-10"
      style="top:-2.5em"
      :href="fields.link.value?.href"
      :target="fields.link.value?.target"
    >{{ fields.link.value?.text }}</a>
  </div>
</template>
