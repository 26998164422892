
<template>
<glue-galleries-static-gridded-gallery-gallery :devise="devise" gallery-name="Three">
<slot></slot></glue-galleries-static-gridded-gallery-gallery>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  data () {
      return {
      }
  },
  computed: {
    currentPage () {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise () {
      return Object.assign({},{ fields: this.fields, page: this.currentPage, slice: this.slice})
    },

  },
  mounted () {
      defineSlice({"slots":[{"name":"default"}],"description":"Adds a gallery to the top-right slot on the gridded gallery","fields":{"title":{"type":"text","label":"Title on Hover"}},"name":{"label":"Static Gridded Gallery Slot Three","group":"Glue"},"templates":[{"label":"Static Gridded Gallery Slot Three","fields":{"title":{"value":"Karaya"}}}]})
  },
};
</script>