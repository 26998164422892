<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      preview: 'SlicesGlueGalleriesStaticGriddedGalleryImage.jpg',
      name: {
        label: 'Static Gridded Gallery Image',
      },
      description: 'Empty container with child slices',
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          editorLabel: true,
          breakpoints: { lg: { width: 1200, height: 675 }, sm: { width: 300, height: 169 } },
        },
      },
      templates: [
        {
          label: 'Static Gridded Gallery Image',
          fields: {
            image: {
              value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis\/looking-forward\/sea-lions\/mom-pup-2.jpg',
            },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <VoixMedia :field="fields.image" />
</template>
