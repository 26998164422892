<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      menus: [],
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    sliceTitle() {
      if (this.fields.title.value)
        return this.fields.title.value

      if (this.currentPage && this.currentPage.title)
        return this.currentPage.title

      return ''
    },
    textColorClass() {
      return {
        'text-blue': this.fields.subTitleColor.value === 'blue',
        'text-red': this.fields.subTitleColor.value === 'red',
      }
    },
    borderColorClass() {
      return {
        'border-blue': this.fields.subTitleColor.value === 'blue',
        'border-red': this.fields.subTitleColor.value === 'red',
      }
    },
  },
  async mounted() {
    defineSlice({ preview: 'SlicesNavigationSubnavigation.jpg', description: 'Title with a subnavigation below it based on the page.', fields: { image: { type: 'media', label: 'Image', enabled: false, breakpoints: { lg: { width: 280, height: 100 } } }, title: { type: 'text', label: 'Title', enabled: true }, titleSize: { type: 'select', label: 'Title Size', allowNull: true, options: { h1: 'H1', h2: 'H2', h3: 'H3', h4: 'H4' } }, subTitle: { type: 'text', label: 'Subtitle', enabled: false }, subTitleColor: { type: 'color', label: 'Subtitle Color', options: { red: 'Red', blue: 'Blue' } }, hideNavigation: { type: 'checkbox', label: 'Hide Navigation', default: false }, menuName: { type: 'text', label: 'Menu Name (Overwrite)' } }, name: { label: 'Subnavigation', group: 'Navigation' }, templates: [{ label: 'Subnavigation', fields: { titleSize: { value: 'h1' } } }] })

    if (!this.fields.hideNavigation.value) {
      let url = `/api/devise/pages/${this.currentPage.id}/sub-menu-items`

      if (this.fields.menuName.value)
        url += `?menu=${this.fields.menuName.value}`

      const { data } = await $voixFetch(url)

      this.menus = data.data
    }
  },
}
</script>

<template>
  <div
    v-if="$voix.breakpoint !== 'default'"
    class="container md:mb-4 mt-4 w-4/5 flex flex-col items-center text-center"
  >
    <VoixMedia :field="fields.image" class="w-1/4" />

    <div v-if="fields.title.enabled" class="uppercase mt-4">
      <h1 v-if="fields.titleSize.value == 'h1'">
        {{ sliceTitle }}
      </h1>
      <h2 v-if="fields.titleSize.value == 'h2'">
        {{ sliceTitle }}
      </h2>
      <h3 v-if="fields.titleSize.value == 'h3'">
        {{ sliceTitle }}
      </h3>
      <h4 v-if="fields.titleSize.value == 'h4'">
        {{ sliceTitle }}
      </h4>
    </div>
    <div
      v-if="fields.title.enabled && fields.subTitle.enabled"
      class="text-blue-800 uppercase flex justify-center items-center font-sans mt-2"
      :class="textColorClass"
    >
      <hr class="border-blue w-16 mr-2" :class="borderColorClass">
      {{ fields.subTitle.value }}
      <hr class="border-blue w-16 ml-2" :class="borderColorClass">
    </div>
    <div v-if="fields.title.enabled" class="w-3/5">
      <hr class="mt-4">
    </div>
    <ul
      v-if="!fields.hideNavigation.value"
      class="flex flex-wrap justify-center items-center w-full"
    >
      <li v-for="(menu, n) in menus" :key="n" class="mx-6 my-2">
        <a
          :href="menu.url"
          class="uppercase text-black font-sans text-xs"
        >{{ menu.name }}</a>
      </li>
    </ul>
  </div>
</template>
