<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({ preview: 'SlicesGlueScavengerHuntWinningForm.jpg', description: 'Scavenger Hunt Winning Form', fields: { title: { label: 'Title', type: 'text' }, copy: { label: 'Copy', type: 'wysiwyg' }, code: { label: 'Code', type: 'text' } }, name: { label: 'Winning Form', group: 'Glue' }, templates: [{ label: 'Winning Form', fields: { copy: { value: '<p><\/p>' }, code: { value: 'Oasis2023' } } }] })
  },
}
</script>

<template>
  <div>
    <glue-scavenger-hunt-winning-form :devise="devise" />
  </div>
</template>
