<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({ slots: [{ name: 'default' }], description: 'Title and description with one or more images to the side of it', fields: { title: { type: 'text', label: 'Title' }, description: { type: 'textarea', label: 'Description' }, link: { type: 'link', label: 'Link', enabled: false }, mirror: { type: 'checkbox', label: 'Mirror' } }, name: { label: 'Title Description Flanking Gallery', group: 'Glue' }, templates: [{ label: 'Title Description Flanking Gallery', fields: { description: { value: 'Whether you\'re a first-time traveler\u00A0or a seasoned Atlantis visitor, our expert tips and recommendations will guide you through every step of Paradise. From insider knowledge about the best times to visit to travel suggestions and local secrets, check out Traveling to Atlantis.' }, link: { value: { text: 'Getting Here', target: '_self', relationship: null, href: '\/new-page' } }, mirror: { value: false }, title: { value: 'TRAVELING TO ATLANTIS' } } }] })
  },
}
</script>

<template>
  <div>
    <glue-sliders-title-and-description-gallery :devise="devise">
      <slot />
    </glue-sliders-title-and-description-gallery>
  </div>
</template>
