<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ratio() {
      return Number.parseInt(this.devise.width.text) / Number.parseInt(this.devise.height.text)
    },
    invertedRatio() {
      return Number.parseInt(this.devise.height.text) / Number.parseInt(this.devise.width.text)
    },
    ratioStyles() {
      return {
        height: `${this.invertedRatio * 100}vw`,
        minWidth: `${this.invertedRatio * 100}vh`,
      }
    },
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({ preview: 'SlicesSlidersSliderVimeoVideo.jpg', description: 'When you provide a Wistia ID this widget fills the screen in height and width with the video', fields: { wistiaId: { type: 'text', label: 'Wistia ID' }, aspectRatio: { type: 'select', label: 'Aspect Ratio', options: { '16 \/ 7': '16:7', '2 \/ 1': '2:1', '16 \/ 9': '16:9 (Default)', '8 \/ 5': '8:5', '4 \/ 3': '4:3' } }, showControls: { type: 'checkbox', label: 'Show Controls' }, link: { type: 'link', label: 'Link URL', instructions: '"If you want to link the video to a specific URL, enter it here. The label field is not used as the entire video is clickable."', enabled: false }, hideDownArrow: { type: 'checkbox', label: 'Hide Down Arrow' } }, name: { label: 'Slider Vimeo Video', group: 'Sliders' }, templates: [{ label: 'Slider Vimeo Video', fields: { aspectRatio: { value: '16 \/ 9' }, showControls: { value: true }, wistiaId: { value: 'p8ev2iqeqz' } } }] })
  },
}
</script>

<template>
  <div>
    <wistia-slide :devise="devise" class="w-full aspect-video" />
  </div>
</template>
