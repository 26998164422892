<script>
import Animations from '@/mixins/Animations'
import { usePageStore } from '@voix/store/pageStore'

export default {
  mixins: [Animations],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      mousingOver: true,
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      preview: 'SlicesGlueEventsEvent.jpg',
      description: 'Event Card',
      fields: {
        image: { label: 'Image', type: 'media', breakpoints: { lg: { width: 700, height: 700 } } },
        blueCardTop: { label: 'Blue Card Top', type: 'text' },
        blueCardBottom: { label: 'Blue Card Bottom', type: 'text' },
        subtitle: { label: 'Subtitle', type: 'text', default: 'Event' },
        title: { label: 'Title', type: 'text', default: 'Event Title' },
        copy: { label: 'Copy', type: 'wysiwyg' },
        dateText: { label: 'Date Text', type: 'text' },
        link: { label: 'Button Link', type: 'link' },
      },
      name: { label: 'Event', group: 'Glue' },
      templates: [{
        label: 'Event',
        fields: {
          blueCardBottom: { value: 'November' },
          blueCardTop: { value: 'featured package' },
          copy: { value: '<p>A college basketball showdown as legendary as Atlantis itself. The 12-game, three-day tournament is regarded as one of the most challenging early-season tournaments.<\/p>' },
          dateText: { value: 'NOV 24 - NOV 26' },
          image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Battle_4_Atlantis\/Villanova-guard-Phil-Booth-%285%29-drives-to-the-basket-guarded-by-Tennessee-forward-Kyle-Alexander-%2811%29-during-the-second-day-of-action-Nov.jpg' },
          link: { value: { text: 'Learn More', target: '_self', relationship: null, href: '\/new-page' } },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="tracking-event-card bg-orange-50 flex flex-col items-center">
    <div
      class="container relative -mt-20"
      style="max-width: 1120px"
      @mouseenter="mousingOver = true"
      @mouseleave="mousingOver = false"
    >
      <div
        class="absolute top-0 left-0 -mt-16 md:-ml-12 p-8 w-40 h-46 bg-glueblue-700 text-white z-10 text-center leading-relaxed flex flex-col items-center"
      >
        <div class="uppercase font-light font-sans tracking-widest">
          {{ fields.blueCardTop.value }}
        </div>
        <div class="border-r border-white h-8 opacity-50 my-1" />
        <div class="font-light font-serif italic text-xl tracking-widest">
          {{ fields.blueCardBottom.value }}
        </div>
      </div>
      <div class="relative z-0 flex flex-col md:flex-row">
        <div class="flex-grow overflow-hidden relative flex">
          <VoixMedia v-slot="slotProps" :field="fields.image" background>
            <div
              class="bg-cover bg-center min-h-96 transform duration-300 w-full h-full"
              :class="{ 'scale-105': mousingOver }"
              :style="{ backgroundImage: `url(${slotProps.image})` }"
            />
          </VoixMedia>
        </div>
        <div class="bg-white p-8 md:w-1/3 pb-24">
          <div class="w-54">
            <div
              class="font-light text-red-500 italic mb-4 text-2xl tracking-wider"
            >
              {{ fields.subtitle.value }}
            </div>

            <div
              class="uppercase font-sans font-light leading-snug text-gray-800 text-4xl mb-4"
            >
              {{ fields.title.value }}
            </div>
          </div>
          <div
            class="text-gray-500 font-sans2 text-15 mb-8 leading-relaxed"
            v-html="fields.copy.value"
          />

          <div class="text-gray-700 uppercase font-sans items-center flex mb-8">
            <IconsCalendar
              class="mr-2 mb-1"
              width="15"
              height="15"
            />
            <span class="text-xs">{{ fields.dateText.value }}</span>
          </div>

          <a
            :href="fields.link.value?.href"
            class="btn-ghost uppercase text-xs text-gray-700 px-8 font-sans border-gray-500 inline-block text-center py-4 leading-none btn-glue-active tracking-wider"
          >
            {{ fields.link.value.text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
