<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    minHeight() {
      if (this.$voix.breakpoint.value !== 'default' && this.$voix.breakpoint.value !== 'sm') {
        return {
          'min-height': '400px',
        }
      }
      return { 'min-height': '250px' }
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Cards: Horizontal Image Card' },
      description: 'A grid gallery along side a title, copy and link.',
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: {
            default: { width: 700, height: 700 },
          },
        },
        imagePosition: {
          type: 'select',
          label: 'Image Position',
          options: { left: 'Left', right: 'Right' },
          default: 'left',
        },
        title: { type: 'text', label: 'Title', editorLabel: true },
        copy: { type: 'wysiwyg', label: 'Copy' },
        link: { type: 'link', label: 'Link', enabled: false },
        showBookNow: { type: 'checkbox', label: 'Show Book Now', default: false },
        icon: { type: 'select', label: 'Icon', allowNull: true, options: { '\/imgs\/ui\/dining-icon-big.png': 'Dining Icon' } },
        columns: { type: 'number', label: 'Number of Columns', default: '"1"' },
      },
      templates: [{
        label: 'Horizontal Image Card',
        fields: {
          image: {
            value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/2022-content/spa/atlantis-stock-image-fitness-center-trainer-guest-beach-yoga-6.jpeg',
          },
          title: {
            value: 'Title',
          },
          copy: {
            value: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pharetra, nisi quis blandit efficitur, mauris est scelerisque orci, et cursus nunc sapien sed justo. Aenean facilisis libero a tellus maximus, vitae ultrices purus dapibus. Donec eget mi leo. Sed posuere odio dolor, non auctor nunc interdum ut. Vestibulum aliquet quam sed lorem semper, et malesuada lectus pulvinar. Praesent in vehicula libero. Sed vulputate convallis massa, non blandit justo convallis a. Sed nec sem et tellus blandit scelerisque ac at lacus. Pellentesque condimentum elit vitae mi tincidunt, sed varius sem efficitur. Fusce at dictum nulla. Ut sed enim et metus blandit interdum. Maecenas pretium lacinia nulla, at consectetur elit semper non.</p>',
          },
        },
      }],
    })
  },
  methods: {
    goToLink() {
      if (this.fields.link?.value?.url)
        location.href = this.fields.link?.value?.url
    },

  },
}
</script>

<template>
  <div class=" py-4">
    <div class="container">
      <div class="w-full grid lg:grid-cols-12 bg-white min-h-[400px]">
        <div
          v-if="fields.image.value && fields.image.value.length > 0"
          class="relative col-span-8  min-h-[400px]" :class="{
            'lg:order-2': fields.imagePosition.value === 'right',
          }"
        >
          <div class="absolute z-0 inset-0">
            <VoixMedia :field="fields.image" class="w-full h-full object-cover object-center" />
          </div>
        </div>

        <div
          class="p-10 text-left" :class="{
            'col-span-4 ': fields.image.value && fields.image.value.length > 0,
            'col-span-12 ': !(fields.image.value && fields.image.value.length > 0),
            'lg:order-1': fields.imagePosition.value === 'right' }"
        >
          <img v-if="fields.icon.value" class="mb-4" :src="fields.icon.value" width="55">
          <h3 class="mb-4 uppercase text-left">
            {{ fields.title.value }}
          </h3>
          <VoixWysiwyg class="prose" :style="{ columns: fields.columns.value }" :field="fields.copy" />
          <div class="flex justify-between items-center">
            <a
              v-if="fields.link?.enabled"
              class="font-sans2 uppercase text-glueblue-600 text-xs font-bold"
              :href="fields.link?.value?.href"
              :target="fields.link?.value?.target"
            >{{ fields.link?.value?.text }}</a>
            <BookingBookNow v-if="fields.showBookNow.value">
              <button class="btn btn-2xs bg-glueblue-600 font-bold text-white">
                Book Now
              </button>
            </BookingBookNow>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
