<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      mousingOver: true,
    }
  },
  mounted() {
    defineSlice({
      description: 'Title, subtitle, description and link with a gallery to the left or right',
      fields: {
        mode: { label: 'Mode', type: 'select', options: { atlantis: 'Atlantis', sapphire: 'Sapphire' } },
        infoPosition: { label: 'Info position', type: 'select', options: { left: 'Left', right: 'Right' } },
        infoTitle: { label: 'Info title', type: 'wysiwyg' },
        infoSubtitle: { label: 'Info subtitle', type: 'wysiwyg', enabled: false },
        infoText: { label: 'Info text', type: 'wysiwyg' },
        infoLink: { label: 'Info link', type: 'link', enabled: false },
        image1: { label: 'Image 1', type: 'media', enabled: false, breakpoints: { lg: { width: 840, height: 400 }, sm: { width: 500, height: 280 } } },
        image2: { label: 'Image 2', type: 'media', enabled: false, breakpoints: { lg: { width: 840, height: 400 }, sm: { width: 500, height: 280 } } },
        image3: { label: 'Image 3', type: 'media', enabled: false, breakpoints: { lg: { width: 840, height: 400 }, sm: { width: 500, height: 280 } } },
        image4: { label: 'Image 4', type: 'media', enabled: false, breakpoints: { lg: { width: 840, height: 400 }, sm: { width: 500, height: 280 } } },
        image5: { label: 'Image 5', type: 'media', enabled: false, breakpoints: { lg: { width: 840, height: 400 }, sm: { width: 500, height: 280 } } },
      },
      name: { label: 'Information Flanking Gallery', group: 'Sapphire' },
      templates: [{
        label: 'Information Flanking Gallery',
        fields: {
          image1: { enabled: true, value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/sapphire/scuba.jpg' },
          infoPosition: { value: 'left' },
          infoSubtitle: { value: '<p>Ready to skydive?<br>Island hop on a private jet?<br>Charter a flight to the moon?<br>(well, maybe!)</p>' },
          infoText: { value: '<p>For the thrill-seeker, the adventurer, the fast lane racer, our list of Sapphire Adventures starts with you. Explore the Bahamas in a day, play with sharks, take a helicopter ride.</p><p>You want to feel free?<br>Let\'s bring out your inner wild.</p>' },
          infoTitle: { value: '<p>Adventure</p>' },
          mode: { value: 'sapphire' },
        },
      }],
    })
  },
}
</script>

<template>
  <div :class="{ 'bg-sapphire-light': fields.mode.value === 'sapphire' }">
    <div
      class="mx-auto container py-24" :class="{ 'text-glueblue-700': fields.mode.value === 'sapphire' }"
      @mouseenter="mousingOver = true" @mouseleave="mousingOver = false"
    >
      <div
        class="flex flex-col-reverse"
        :class="{ 'md:flex-row': fields.infoPosition.value === 'left', 'md:flex-row-reverse': fields.infoPosition.value === 'right' }"
      >
        <div
          class="w-full md:w-1/4 mt-12 md:mt-0 px-8"
          :class="{ 'md:pl-0': fields.infoPosition.value === 'left', 'md:pr-0': fields.infoPosition.value === 'right' }"
        >
          <div class="text-4xl leading-10" v-html="fields.infoTitle.value" />
          <div v-if="fields.infoSubtitle.enabled" class="text-xl" v-html="fields.infoSubtitle.value" />

          <div class="text-base font-light leading-5 mt-4 font-sans2" v-html="fields.infoText.value" />

          <a
            v-if="fields.infoLink.enabled"
            :href="fields.infoLink.value?.href"
            :target="fields.infoLink.value?.target"
            class="glue-btn text-xs tracking-widest flex-grow pt-3 px-16 cursor-not-allowed text-white"
            :class="{ 'bg-glueblue-700': fields.mode.value === 'atlantis', 'bg-sapphire': fields.mode.value === 'sapphire' }"
          >
            {{ fields.infoLink.value?.text }}
          </a>
        </div>
        <div class="w-full md:w-3/4">
          <slither-slider :options="{ secondsOnSlide: 5, controls: true, dots: false }">
            <div
              v-if="fields.image1.enabled"
              class="bg-cover bg-center min-h-[500px] transform duration-300 w-full h-full"
              :class="{ 'scale-105': mousingOver }"
              :style="{ backgroundImage: `url(${fields.image1.value?.[0]?.url})` }"
            />

            <div
              v-if="fields.image2.enabled"
              class="bg-cover bg-center min-h-[500px] transform duration-300 w-full h-full"
              :class="{ 'scale-105': mousingOver }"
              :style="{ backgroundImage: `url(${fields.image2.value?.[0]?.url})` }"
            />

            <div
              v-if="fields.image3.enabled"
              class="bg-cover bg-center min-h-[400px] transform duration-300 w-full h-full"
              :class="{ 'scale-105': mousingOver }"
              :style="{ backgroundImage: `url(${fields.image3.value?.[0]?.url})` }"
            />

            <div
              v-if="fields.image4.enabled"
              class="bg-cover bg-center min-h-[400px] transform duration-300 w-full h-full"
              :class="{ 'scale-105': mousingOver }"
              :style="{ backgroundImage: `url(${fields.image4.value?.[0]?.url})` }"
            />

            <div
              v-if="fields.image5.enabled"
              class="bg-cover bg-center min-h-[400px] transform duration-300 w-full h-full"
              :class="{ 'scale-105': mousingOver }"
              :style="{ backgroundImage: `url(${fields.image5.value?.[0]?.url})` }"
            />

            <template #previous>
              <div
                class="slider-button ml-8 mr-4 mt-4 hover:text-white"
                :class="{ 'text-sapphire': fields.mode.value === 'sapphire', 'text-gray-700': fields.mode.value === 'atlantis' }"
              >
                <svg class="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" /></svg>
              </div>
            </template>
            <template #next>
              <div
                class="slider-button mt-4  hover:text-white"
                :class="{ 'text-sapphire': fields.mode.value === 'sapphire', 'text-gray-700': fields.mode.value === 'atlantis' }"
              >
                <svg class="w-7 h-7  transform rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" /></svg>
              </div>
            </template>
          </slither-slider>
        </div>
      </div>
    </div>
  </div>
</template>
