<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      open: false,
    }
  },

  mounted() {
    defineSlice({
      preview: 'SlicesGlueHistoryHistoryGroup.jpg',
      slots: [{ name: 'default' }],
      description: 'History Group',
      fields: {
        type: { type: 'select', label: 'Type', options: { 'future': 'Future (hidden)', 'default': 'Recent (shown)', 'past-hidden': 'Past (hidden)' } },
      },
      name: { label: 'History Group', group: 'Glue' },
      templates: [{
        label: 'History Group',
        fields: { type: { value: 'future' } },
      }],
    })

    if (this.fields.type.value === 'default')
      this.open = true
  },
}
</script>

<template>
  <div>
    <div v-if="fields.type.value === 'future' && !open" class="flex justify-center py-8">
      <button class="group flex flex-col items-center space-y-2" @click="open = true">
        <svg
          width="30"
          height="8"
          viewBox="0 0 39 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="text-gray-400"
        >
          <path
            d="M20.0942 -0.831002C19.9438 -0.937999 19.7269 -0.999998 19.5005 -0.999998C19.2741 -0.999998 19.0572 -0.937999 18.9067 -0.831001L0.698975 12.1687C0.409233 12.3757 0.43932 12.6907 0.767059 12.8747C1.09163 13.0567 1.59512 13.0387 1.88486 12.8317L19.5005 0.253978L37.1145 12.8307C37.4042 13.0377 37.9061 13.0567 38.2323 12.8737C38.56 12.6907 38.5917 12.3747 38.3004 12.1677L20.0942 -0.831002Z"
            fill="currentColor"
          />
        </svg>

        <div
          class="text-center font-bold font-sans uppercase text-xs border-b-2 border-transparent group-hover:border-red-300 transition-colors duration-300"
        >
          See What's Coming
        </div>
      </button>
    </div>
    <div
      v-if="fields.type.value === 'default'"
      class="relative flex justify-center md:justify-end"
    >
      <div class="md:w-1/2 flex space-x-5">
        <div
          class="hidden md:block rounded-full w-4 h-4 flex-none bg-red-300 -translate-x-2"
        />
        <div
          class="hidden md:block w-1/3 border-b-2 border-red-300 border-dashed -translate-y-2.5"
        />
        <div class="group flex flex-col items-center space-y-2">
          <div
            class="text-center font-bold font-sans uppercase text-base md:text-xs text-red-300"
          >
            Present Day
          </div>
        </div>
      </div>
    </div>

    <transition
      enter-active-class="transition duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
    >
      <div v-show="open">
        <template v-for="(element, key) in slice.elements" :key="key">
          <VoixSlice :slice="element" :slice-index="key" />
        </template>
      </div>
    </transition>

    <div
      v-if="fields.type.value === 'past-hidden' && !open"
      class="flex justify-center py-8"
    >
      <button class="group flex flex-col items-center space-y-2" @click="open = true">
        <div
          class="text-center font-bold font-sans uppercase text-xs border-b-2 border-transparent group-hover:border-red-300 transition-colors duration-300"
        >
          View More History
        </div>
        <svg
          width="30"
          height="8"
          viewBox="0 0 39 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="text-gray-400 rotate-180"
        >
          <path
            d="M20.0942 -0.831002C19.9438 -0.937999 19.7269 -0.999998 19.5005 -0.999998C19.2741 -0.999998 19.0572 -0.937999 18.9067 -0.831001L0.698975 12.1687C0.409233 12.3757 0.43932 12.6907 0.767059 12.8747C1.09163 13.0567 1.59512 13.0387 1.88486 12.8317L19.5005 0.253978L37.1145 12.8307C37.4042 13.0377 37.9061 13.0567 38.2323 12.8737C38.56 12.6907 38.5917 12.3747 38.3004 12.1677L20.0942 -0.831002Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>
  </div>
</template>
