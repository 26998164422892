<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({ preview: 'SlicesGlueGenericCardsThinImage.jpg', description: 'Thin image with title, description, and link', fields: { image: { label: 'Image', type: 'media', breakpoints: { lg: { width: 1500, height: 380 }, sm: { width: 315, height: 315 } } }, title: { label: 'Title', type: 'text' }, description: { label: 'Description', type: 'wysiwyg' }, link: { label: 'Link', type: 'link', enabled: false } }, name: { label: 'Thin Image', group: 'Glue' }, templates: [{ label: 'Thin Image', fields: { description: { value: '<p>The health and safety of our guests and associates has always been, and will always be, our top priority. View our water park rules, height requirements, and procedures to ensure your safety and comfort.<\/p>' }, image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis-baths-collonade-pool-girl-swimming-underwater-webgallery.jpg' }, link: { value: { text: 'learn more', target: '_self', relationship: null, href: '\/new-page' } }, title: { value: 'YOUR SAFETY IS OUR PRIORITY' } } }] })
  },
}
</script>

<template>
  <div class="p-16">
    <div class="relative overflow-hidden">
      <VoixMedia :field="fields.image" class="object-cover absolute inset-0 w-full h-full" />

      <div class="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />

      <div class="relative z-10 mt-48 px-12 py-8 text-white">
        <div class="headline-2">
          {{ fields.title.value }}
        </div>
        <div class="mt-2 font-sans2 max-w-[900px]" v-html="fields.description.value" />
        <a v-if="fields.link.enabled" :href="fields.link.value?.href" :target="fields.link.value?.target" class="inline-block mt-6 border border-white text-white font-bold px-8 py-5 leading-snug uppercase focus:outline-none font-sans text-xs">
          {{ fields.link.value?.text }}
        </a>
      </div>
    </div>
  </div>
</template>
