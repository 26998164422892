<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      preview: 'SlicesAudioAudioPlayer.jpg',
      fields: {
        audioUrl: { type: 'text', label: 'Audio URL' },
      },
      name: { label: 'Audio Player', group: 'Audio' },
      templates: [{
        label: 'Audio Player',
        fields: {
          audioUrl: {
            value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/The%20Dig%20Tour/2329-1c6c-43e1-8a62-cf78da6b1969.mp3',
          },
        },
      }],
    })
  },
}
</script>

<template>
  <div class=" bg-orange-100 py-12">
    <div class="container mx-auto flex justify-center">
      <audio class="w-full" controls>
        <source :src="fields.audioUrl.value" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>
    </div>
  </div>
</template>
