<script>
import { usePageStore } from '@voix/store/pageStore'
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      preview: 'SlicesGlueGenericCardsBookingCard.jpg',
      description: 'Card with gallery at the top and a booking button at the bottom able to carry a cobrand',
      fields: {
        title: { type: 'text', label: 'Title' },
        subtitle: { type: 'text', label: 'Subtitle' },
        copy: { type: 'wysiwyg', label: 'Copy' },
        cobrand: { type: 'text', label: 'Cobrand' },
        link: { type: 'link', label: 'Link', enabled: false },
        image1: { type: 'media', label: 'Image 1', enabled: false, breakpoints: { lg: { width: 350, height: 200 } } },
        image2: { type: 'media', label: 'Image 2', enabled: false, breakpoints: { lg: { width: 350, height: 200 } } },
        image3: { type: 'media', label: 'Image 3', enabled: false, breakpoints: { lg: { width: 350, height: 200 } } },
        image4: { type: 'media', label: 'Image 4', enabled: false, breakpoints: { lg: { width: 350, height: 200 } } },
      },
      name: { label: 'Booking Card', group: 'Glue' },
      templates: [{
        label: 'Booking Card',
        fields: {
          cobrand: { value: 'apiOffer8web' },
          copy: { value: '<p>Located on the top five floors, this 1-bedroom club-level suite \nboasts 1,198 square feet of living space and breathtaking views \nof the ocean and the Atlantis waterscape. This island escape \noffers an open floor plan with ample living space, floor-to\u0002ceiling window, and a fully furnished balcony for relaxation. The \nbedroom feature a King Bed. The living area offers a queen \nsleeper sofa for added comfort. The oversized bathroom \ninclude a deep soaking tub and separate shower with rainfall \nshowerhead.<\/p><p><br><\/p><p>1 King Bed \nand \n1 Sleeper \nSofa<br\/>\n5 Guests\n(Max 4 + \n1 child \nunder \n12)<\/p>' },
          image1: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Rooms\/The_Cove_Atlantis\/Azure_Suites\/Hero\/hero-bedroom-azure-suites-web-image-update.jpg' },
          image2: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis-aquaventure-power-tower-the-surge-waterslide.jpg' },
          title: { value: '1 Bedroom  Azure Suite' },
        },
      }],
    })
  },
}
</script>

<template>
  <div
    class="booking-card bg-white font-sans2 text-center text-gray-500 flex flex-col justify-between"
  >
    <div>
      <Swiper
        :slides-per-view="1" :centered-slides="true"
      >
        <SwiperSlide>
          <VoixMedia :field="fields.image1" class="w-full" />
        </SwiperSlide>
        <SwiperSlide v-if="fields.image2.value">
          <VoixMedia :field="fields.image2" class="w-full" />
        </SwiperSlide>
        <SwiperSlide v-if="fields.image3.value">
          <VoixMedia :field="fields.image3" class="w-full" />
        </SwiperSlide>
        <SwiperSlide v-if="fields.image4.value">
          <VoixMedia :field="fields.image4" class="w-full" />
        </SwiperSlide>
      </Swiper>

      <div class="mt-16 px-6">
        <div class="py-4 text-center uppercase font-bold tracking-widest text-red-300">
          {{ fields.title.value }}
        </div>

        <div class="mb-4">
          {{ fields.subtitle.value }}
        </div>

        <div class="text-sm" v-html="fields.copy.value" />
      </div>
    </div>

    <div class="px-8 mb-6">
      <BookingBookNow :cobrand="fields.cobrand.value" location="booking-card" disableable>
        <button
          class="mt-6 btn glue-btn btn-sm w-full p-4 text-xs bg-white text-black border border-gray-900"
        >
          Book Now
        </button>
      </BookingBookNow>

      <div v-if="fields.link.enabled" class="mt-4">
        <a :href="fields.link.value?.href">{{ fields.link.value.text }}</a>
      </div>
    </div>
  </div>
</template>
