<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    track: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      filters: {
        categories: [{ id: 0, name: 'Featured', selected: true }],
        dates: {
          start: null,
          end: null,
        },
      },
      pageSpecials: [],
      pageSpecialsCategories: [],
    }
  },
  computed: {
    featuredCategoryInFilter() {
      const featuredCategoryFilter = this.filters.categories.find((fc) => {
        return fc.id === 0
      })
      return featuredCategoryFilter ? featuredCategoryFilter.selected : false
    },
    categoriesInFilter() {
      if (this.filters.categories.length > 1) {
        return this.pageSpecialsCategories.filter((category) => {
          return this.filters.categories.find((fc) => {
            return fc.id === category.id && fc.selected
          })
        })
      }
      return this.pageSpecialsCategories
    },
  },
  mounted() {
    defineSlice({
      preview: 'SlicesGlueSpecialsSpecials.jpg',
      slots: [{ name: 'default' }],
      description: 'Specials',
      fields: {
        orderby: {
          type: 'select',
          label: 'Order By',
          options: {
            end_date: 'End Date',
            priority: 'Priority',
          },
        },
        hideSearch: { type: 'checkbox', label: 'Hide Search' },
        category: { type: 'select', label: 'Category', instructions: '"\\"Specials Landing Page\\" if nothing is selected"', options: [] },
      },
      name: { label: 'Specials', group: 'Glue' },
      templates: [{
        label: 'Specials',
        fields: {
          category: { value: '45' },
          hideSearch: { value: true },
        },
      }],
    })

    this.resetFilter()
    this.requestSpecialsCategories()
    this.requestPageSpecials()
  },
  methods: {
    async requestPageSpecials() {
      const filters = {
        category_id: this.fields.category.value
          ? this.fields.category.value
          : '45',
        orderby: this.fields.orderby.value
          ? this.fields.orderby.value
          : 'end_date',
      }
      const query = new URLSearchParams(filters).toString()

      const data = await $voixFetch(`/api/devise/page-specials/all?${query}`)

      if (data)
        this.pageSpecials = data.data
    },
    async requestSpecialsCategories() {
      const data = await $voixFetch('/api/devise/page-specials/categories')

      if (data)
        this.pageSpecialsCategories = data.data
    },
    resetFilter() {
      this.filters.dates.start = null
      this.filters.dates.end = null
      this.filters.categories.map((category) => {
        category.selected = true
      })
    },
    scrollToAllSpecials() {
      const options = {
        easing: 'ease-out',
        offset: -100,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      }

      const element = document.getElementById('specials-all-offers-category')
      VueScrollTo.scrollTo(element, 1000, options)
    },
  },
}
</script>

<template>
  <div
    v-if="pageSpecialsCategories"
    class="relative pb-16 pt-24 mb-20 md:mb-0 flex flex-col items-center mx-auto"
    style="max-width: 1248px"
  >
    <div class="px-8 md:px-16 w-full">
      <GlueSpecialsSpecialsCategory
        id="specials-all-offers-category"
        :category="{ name: 'All Offers' }"
        :filters="filters"
        :track="track"
        :page-specials="pageSpecials"
        :page-specials-categories="pageSpecialsCategories"
        class="tracking-all-offers mb-20"
      />
    </div>
  </div>
</template>
