
<template>
    <div>
        <glue-experience-shopping :devise="devise"></glue-experience-shopping>
    </div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  data () {
      return {
      }
  },
  computed: {
    currentPage () {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise () {
      return Object.assign({},{ fields: this.fields, page: this.currentPage, slice: this.slice})
    },

  },
  mounted () {
      defineSlice({"description":"Shops WIdget","fields":{"courtImage":{"type":"media","label":"Crystal Court Image","breakpoints":{"lg":{"width":1160,"height":690},"default":{"width":315,"height":315}}},"escapeRoomImage":{"type":"media","label":"Escape At The Cove Image","breakpoints":{"lg":{"width":1160,"height":690},"default":{"width":315,"height":315}}},"logoStoreImage":{"type":"media","label":"Atlantis Logo Stores Image","breakpoints":{"lg":{"width":1160,"height":690},"default":{"width":315,"height":315}}},"marineImage":{"type":"media","label":"Marine Village Image","breakpoints":{"lg":{"width":1160,"height":690},"default":{"width":315,"height":315}}},"marineLink":{"type":"link","label":"Marine Village Link","enabled":false},"proShopImage":{"type":"media","label":"Pro Shop Image","breakpoints":{"lg":{"width":1160,"height":690},"default":{"width":315,"height":315}}}},"name":{"label":"Shops","group":"Experience"},"templates":[{"label":"Shops","fields":{"courtImage":{"value":"https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/experiences\/ds-atlantis-shopping-4461-r2.jpeg"},"escapeRoomImage":{"value":"https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Things_To_Do\/Activities\/Shopping\/Gallery\/InPage-Photogallery-SHOPPING_escape2.jpg"},"logoStoreImage":{"value":"https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Shops\/the-coral-atlantis-kids-store-families-shopping.jpg"},"marineImage":{"value":"https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/atlantis-marina-village-family-1.jpg"},"proShopImage":{"value":"https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Offers\/redesign\/ultimate-golf-package\/pro-shop.jpg"}}}]})
  },
};
</script>