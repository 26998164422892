<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      preview: 'SlicesGlueMagazineSliderMagazineSlide.jpg',
      description: 'Magazine Slide',
      fields: {
        image: {
          label: 'Image',
          type: 'media',
          editorLabel: true,
          breakpoints: {
            lg: { width: 1600, height: 760 },
            md: { width: 400, height: 720 },
          },
        },
        isRight: { label: 'Title Right?', type: 'checkbox' },
        isCompact: { label: 'Title Compact?', type: 'checkbox', default: false },
        subTitle: { label: 'Sub-Title', type: 'text' },
        title: { label: 'Title', type: 'text' },
        description: { label: 'Description', type: 'textarea' },
        link: { label: 'Link', type: 'link', enabled: false },
        wistiaId: { type: 'text', label: 'Wistia ID' },
      },
      name: { label: 'Magazine Slide', group: 'Glue' },
      templates: [{
        label: 'Magazine Slide',
        fields: {
          description: { value: 'Learn how Atlantis continues to lead the charge toward a sustainable future in The Bahamas.' },
          image: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/glue/home/home-bg-scaled.jpg' },
          isRight: { value: true },
          link: { value: { text: 'Learn More', target: '_blank', relationship: null, href: '/new-page' } },
          subTitle: { value: 'Atlantis' },
          title: { value: 'MAKING WAVES' },
        },
      }],
    })
  },
}
</script>

<template>
  <glue-magazine-slider-magazine-slide :devise="devise" />
</template>
