<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({ preview: 'SlicesMusicMakingWavesMmwVenueCards.jpg', description: 'Music Making Waves Venue Cards', fields: { image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 500, height: 500 }, sm: { width: 300, height: 300 } } }, color: { type: 'select', label: 'Color', options: { black: 'Black', blue: 'Blue', orange: 'Orange', teal: 'Teal' } }, title: { type: 'text', label: 'Title' }, description: { type: 'wysiwyg', label: 'Description' } }, name: { label: 'Mmw Venue Cards', group: 'Music Making Waves' }, templates: [{ label: 'Mmw Venue Cards', fields: { color: { value: 'black' }, description: { value: '<p>Endless deliciousness awaits! Get your taste buds ready with an assortment of food and snack options from a variety of food trucks to accommodate all of your cravings. Satisfy your hunger without having to go far!<\/p>' }, image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/MMW\/Janet%20Jackson\/dc1-8810.JPG' }, title: { value: 'food trucks' } } }] })
  },
}
</script>

<template>
  <div class="px-4 lg:px-0">
    <div>
      <div>
        <VoixMedia :field="fields.image" class="object-cover w-full h-full" />
      </div>
      <div
        class="uppercase p-4 px-6 text-[32px] font-mmw-headline rounded-br-3xl leading-none" :class="{
          'bg-black text-mmw-orange': fields.color.value === 'black',
          'bg-mmw-blue text-mmw-teal': fields.color.value === 'blue',
          'bg-mmw-orange text-black': fields.color.value === 'orange',
          'bg-mmw-teal text-white': fields.color.value === 'teal',
        }"
      >
        {{ fields.title.value }}
      </div>
    </div>
    <div class="mt-4 font-mmw" v-html="fields.description.value" />
  </div>
</template>
