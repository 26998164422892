<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    imageSizes() {
      if (this.$voix.breakpoint.value === 'mobile' || this.$voix.breakpoint.value === 'tablet') {
        return {
          'min-width': '110px',
          'min-height': '151px',
        }
      }

      return {
        'min-width': '164px',
        'min-height': '225px',
      }
    },
  },
  mounted() {
    defineSlice({
      preview: 'SlicesGlueGenericCardsContactCard.jpg',
      description: 'Empty container with child slices',
      fields: {
        title: { type: 'text', label: 'Title' },
        description: { type: 'wysiwyg', label: 'Description' },
        image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 164, height: 225 }, sm: { width: 110, height: 151 } } },
        phone: { type: 'text', label: 'Phone Number', enabled: false },
        email: { type: 'text', label: 'Email', enabled: false },
      },
      name: { label: 'Contact Card', group: 'Glue' },
      templates: [{
        label: 'Contact Card',
        fields: {
          description: { value: '<p><br><br><\/p>' },
          email: { enabled: true, value: 'Jack.Edelman@atlantisparadise.com' },
          image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Casino\/Casino_Host\/CasinoHost_JackEdelman.jpg' },
          phone: { enabled: true, value: '877-508-1593' },
          title: { value: 'Jack Edelman' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="flex">
    <VoixMedia v-slot="slotProps" :field="fields.image" background>
      <div
        class="bg-center bg-no-repeat bg-cover"
        :style="{ backgroundImage: `url(${slotProps.image})`, ...imageSizes }"
      />
    </VoixMedia>
    <div class="bg-white p-6 md:p-10 flex items-center w-full">
      <div>
        <div class="font-sans text-xl font-light uppercase">
          {{ fields.title.value }}
        </div>
        <div
          class="font-sans2 text-sm w-full text-gray-600"
          v-html="fields.description.value"
        />
        <div class="flex space-x-4 text-gray-600">
          <div
            v-if="fields.phone.enabled"
            class="flex space-x-2 items-center text-sm md:text-xs font-sans2 font-normal underline"
          >
            <svg
              class="w-5 h-5 md:w-3 md:h-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
            <a :href="`tel:${fields.phone.value}`">{{ fields.phone.value }}</a>
          </div>
          <div
            v-if="fields.email.enabled"
            class="flex space-x-2 items-center text-sm md:text-xs font-sans2 font-normal underline"
          >
            <svg
              class="w-5 h-5 md:w-3 md:h-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <a :href="`mailto:${fields.email.value}`">Email</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
