<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      preview: 'SlicesTwentyFifthAnniversaryLargeTextOverImage.jpg',
      description: 'Large text ',
      fields: { topText: { type: 'text', label: 'Top Text' }, topBigText: { type: 'text', label: 'Top Big Text' }, image: { type: 'media', label: 'Image', editorLabel: true, breakpoints: { sm: { width: 500, height: 300 }, lg: { width: 1000, height: 562 } } }, bottomBigText: { type: 'text', label: 'Bottom Big Text' }, bottomText: { type: 'text', label: 'Bottom Text' }, link: { type: 'link', label: 'Link' } },
      name: { label: 'Large Text Over Image', group: '25th Anniversary' },
      templates: [
        {
          label: 'Large Text Over Image',
          fields: {
            bottomBigText: { value: 'celebration' },
            bottomText: { value: '25 years in the making' },
            image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Events\/2023\/25th%20Anniversary\/atlantis-2022-scaletta-8084.jpg' },
            link: { value: { text: '25 years: a look back', target: '_self', relationship: null, href: '\/new-page' } },
            topBigText: { value: 'Royal' },
            topText: { value: 'Don\'t miss the' },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <div class="flex flex-col items-center py-12">
    <div class="uppercase text-2xl font-sans font-thin">
      {{ fields.topText.value }}
    </div>
    <div class="h-20 my-4 border-r border-zinc-600" />
    <div class="relative lg:-mt-8 z-10 text-[64px] lg:text-[120px] font-annv font-thin">
      {{ fields.topBigText.value }}
    </div>
    <div class="relative z-0 -my-12 lg:-my-20 w-full max-w-[1000px] aspect-video relative">
      <VoixMedia :field="fields.image" class="absolute top-0 left-0 w-full h-full object-cover" />
      <div class="absolute top-0 left-0 right-0 h-40 bg-gradient-to-b from-white/50 to-transparent" />
      <div class="absolute bottom-0 left-0 right-0 h-40 bg-gradient-to-t from-white/50 to-transparent" />
    </div>
    <div class="relative z-10  text-[64px] lg:text-[120px] font-annv font-thin">
      {{ fields.bottomBigText.value }}
    </div>
    <div class="uppercase text-2xl font-sans font-thin">
      {{ fields.bottomText.value }}
    </div>
    <div class="h-20 my-8 border-r border-zinc-600" />
    <a :href="fields.link.value?.href" :target="fields.link.value?.target" class="glue-btn btn-ghost border-zinc-600 text-zinc-600 uppercase btn-ghost text-2xs tracking-widest py-2 px-4 font-bold">
      {{ fields.link.value?.text }}
    </a>
  </div>
</template>
