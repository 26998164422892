<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
    mousingOver: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      preview: 'SlicesGlueGenericCardsHorizontalImageCard.jpg',
      description: 'Generic Horizontal Card',
      fields: { image: { label: 'Image', type: 'media', breakpoints: { lg: { width: 700, height: 700 } } }, subtitle: { label: 'Subtitle', type: 'text' }, title: { label: 'Title', type: 'text' }, copy: { label: 'Copy', type: 'wysiwyg' }, link: { label: 'Button Link', type: 'link' }, left: { label: 'Content on Left?', type: 'checkbox', defaults: { value: false } } },
      name: {
        label: 'Glue: Horizontal Image Card',
        group: 'Glue',
      },
      templates: [{
        label: 'Glue: Horizontal Image Card',
        fields: {
          copy: { value: '<p>Make every occasion to hold a meeting or event, uniquely memorable.</p>' },
          image: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/Offers/2020/groups/royal-deck-1.jpg' },
          left: { value: true },
          link: { value: { text: 'See Specials for Events', target: '_self', relationship: null, href: '/new-page' } },
          subtitle: { value: 'Special Occasions' },
          title: { value: 'A Special place to meet' },
        },
      }],
    })
  },
}
</script>

<template>
  <div>
    <div
      class="container relative p-8 py-16 md:p-0 md:py-0"
      style="max-width: 1120px;"
      @mouseenter="mousingOver = true"
      @mouseleave="mousingOver = false"
    >
      <div
        class="relative z-0 flex flex-col"
        :class="{
          'md:flex-row-reverse': fields.left.value,
          ' md:flex-row': !fields.left.value,
        }"
      >
        <div class="flex-grow overflow-hidden relative flex">
          <VoixMedia v-slot="slotProps" :field="fields.image" background>
            <div
              class="bg-cover bg-center min-h-96 transform duration-300 w-full h-full"
              :class="{ 'scale-105': mousingOver }"
              :style="{ backgroundImage: `url(${slotProps.image})` }"
            />
          </VoixMedia>
        </div>
        <div class="bg-white p-8 md:p-16 md:w-110 md:pb-24">
          <div class="w-54">
            <div
              class="font-light text-red-500 italic mb-4 text-2xl tracking-wider"
            >
              {{ fields.subtitle.value }}
            </div>

            <div
              class="uppercase font-sans font-light text-gray-800 text-3xl md:text-4xl mb-4 leading-snug tracking-wider"
            >
              {{ fields.title.value }}
            </div>
          </div>
          <div
            class="text-gray-500 font-sans2 mb-16 text-15 leading-relaxed"
            v-html="fields.copy.value"
          />

          <a
            :href="fields.link.value?.href"
            class="btn-ghost uppercase text-xs text-gray-700 px-10 font-sans border-gray-500 inline-block text-center py-4 leading-none btn-glue-active tracking-wider"
          >
            {{ fields.link.value.text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
