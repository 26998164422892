<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({ preview: 'SlicesSapphireBlockquoteOnBackground.jpg', description: 'Blockquote on top of an image background', fields: { mode: { label: 'Mode', type: 'select', options: { atlantis: 'Atlantis', sapphire: 'Sapphire' } }, backgroundImage: { label: 'Background Image', type: 'media', breakpoints: { lg: { width: 1600, height: 950 }, md: { width: 400, height: 900 } } }, topImage: { label: 'Top Image', type: 'media', enabled: false, breakpoints: { lg: { width: 150, height: 150 } } }, quote: { label: 'Quote', type: 'wysiwyg' }, attribution: { label: 'Attribution', type: 'text', enabled: false } }, name: { label: 'Blockquote On Background', group: 'Sapphire' }, templates: [{ label: 'Blockquote On Background', fields: { mode: { value: 'atlantis' }, backgroundImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/sapphire\/clouds-and-butterflies.jpg' }, topImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/sapphire\/sapphire-services-logo.png' }, quote: { value: '<p>Sapphire Services caters to the elite traveler looking for highly curated and exclusive experiences by Atlantis Resort.<\/p>' }, attribution: { value: 'Now close your eyes and dream' } } }] })
  },
}
</script>

<template>
  <div
    class=" mx-auto text-sapphire-light"
    :class="{ 'text-sapphire-light': fields.mode.value === 'sapphire', 'text-white': fields.mode.value === 'atlantis' }"
  >
    <div
      :style="{ backgroundImage: `url(${fields.backgroundImage.value?.[0]?.url})` }"
      class="bg-cover bg-center pt-20 pb-16"
    >
      <div class="container flex flex-col items-center justify-center text-center">
        <div v-if="fields.topImage.enabled">
          <VoixMedia :field="fields.topImage" lazy="{image: fields.topImage, $voix.breakpoint}" />
        </div>

        <div class="max-w-lg text-4xl leading-10 px-6 font-serif" v-html="fields.quote.value" />

        <div v-if="fields.attribution.enabled" class="h-24 border-r border-white my-6" />

        <div v-if="fields.attribution.enabled" class="text-base font-sans2">
          {{ fields.attribution.value }}
        </div>
      </div>
    </div>
  </div>
</template>
