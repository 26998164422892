<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      origin: 'https%3A%2F%2Fatlantisbahamas.com%2F',
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      preview: 'SlicesBookingInlineCalendar.jpg',
      description: 'Information Block',
      fields: { title: { type: 'text', label: 'Title', editorLabel: true, default: '"BOOK YOUR EXPERIENCE TODAY"' }, copy: { type: 'wysiwyg', label: 'Introduction', enabled: true }, buttonLabel: { type: 'text', label: 'Button Label', default: '"Search"' }, mode: { type: 'select', label: 'Date Mode', options: { inline: 'Single Date', duo: 'Two Dates' } } },
      name: { label: 'Inline Calendar', group: 'Booking' },
      templates: [{ label: 'Inline Calendar', fields: { copy: { value: '<p><\/p><p><\/p><p><\/p><p><br><\/p><p><\/p>' }, mode: { value: 'duo' } } }],
    })
    const encodedPath = encodeURIComponent(this.currentPage.path)
    this.origin = `https%3A%2F%2Fatlantisbahamas.com${encodedPath}%2F`
  },
}
</script>

<template>
  <div>
    <div class="container mb-24 text-align-center">
      <div class="flex items-center justify-center bg-white p-8">
        <BookingMiniInlineBooking :origin="origin" prefill-check-in="" prefill-check-out="" :devise="devise" />
      </div>
    </div>
  </div>
</template>
