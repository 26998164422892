<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({ preview: 'SlicesOverridesHideNavigationBookNow.jpg', description: 'This slice has no display but will hide the navigation book now button. Placement in order does not matter.', fields: [], name: { label: 'Hide Navigation Book Now', group: 'Overrides' }, templates: [{ label: 'Hide Navigation Book Now', fields: [] }] })
    // @todo
    // $voix.bus.$emit('hide-navation-book-now')
    // setTimeout(() => {
    //   $voix.bus.$emit('hide-navation-book-now')
    // }, 1000)
  },
}
</script>

<template>
  <div />
</template>
