<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    linkClassObject() {
      return {
        'book-now': (this.fields.link.enabled && this.fields.link?.value?.text.toLowerCase() === 'book now'),
      }
    },

    textColor() {
      try {
        if (
          Object.prototype.hasOwnProperty.call(this.slice, 'properties')
          && Object.prototype.hasOwnProperty.call(this.slice.properties, 'settings')
          && Object.prototype.hasOwnProperty.call(this.slice.properties.settings, 0)
          && Object.prototype.hasOwnProperty.call(this.slice.properties.settings[0], 'color')
          && this.slice.properties.settings[0].color
        )
          return `color: ${this.slice.properties.settings[0].color};`
      }
      catch (e) {
        console.error(e)
      }

      return ''
    },
  },
  mounted() {
    defineSlice({
      description: 'Contains the content to a max width',
      fields: {
        containerSize: {
          type: 'select',
          label: 'Container Size',
          options: { 'max-w-[600px]': 'Small', 'max-w-[750px]': 'Medium', 'max-w-[900px]': 'Large', 'w-full': 'Extra Large' },
          default: 'container-md',
        },
        textPosition: {
          type: 'select',
          label: 'Text Position',
          options: { 'text-left items-start': 'Left', 'text-center items-center': 'Center', 'text-right items-end': 'Right' },
          default: 'text-center items-center',
        },

        title: { type: 'text', label: 'Title', editorLabel: true, enabled: true },
        titleSize: {
          type: 'select',
          label: 'Title Size',
          options: {
            h1: 'H1',
            h2: 'H2',
            h3: 'H3',
            h4: 'H4',
          },
          default: 'h1',
        },
        introduction: { type: 'wysiwyg', label: 'Introduction', enabled: true },
        proseSize: { type: 'select', label: 'Text Size', allowNull: true, options: { 'prose-sm': 'Small', 'prose-base': 'Base', 'prose md:prose-lg': 'Large', 'prose md:prose-xl': 'Extra Large' } },
        link: {
          type: 'link',
          label: 'Link',
          instructions: '"To launch the booking modal use \\"Book Now\\" as the label value."',
          enabled: false,
        },
      },
      name: { label: 'Title Introduction', group: 'Copy' },
      templates: [{
        label: 'Title Introduction',
        fields: {
          introduction: { value: '<p>This restaurant is currently closed.&nbsp; We apologize or any inconvenience.&nbsp; Please join us at one of our other restaurants. <a href="https:\/\/files.atlantisbahamas.com\/all\/available-amenities\/Atlantis_Reopening_111020B.pdf?_ga=2.185904097.958773058.1607955931-317145088.1606234251" rel="noopener noreferrer nofollow">Click here for restaurant information<\/a><a href="\u201Chttps:\/\/www.atlantisbahamas.com\/travel-info\u201D" rel="noopener noreferrer nofollow">.<\/a><\/p>' },
          titleSize: { value: 'h1' },
        },
      }],
    })
  },
}
</script>

<template>
  <div
    class="container mt-8 mb-8 md:mb-12 w-full px-8 md:w-4/5 flex flex-col"
    :class="[fields.containerSize.value, fields.textPosition.value]"
  >
    <div v-if="fields.title.enabled && fields.title.value" class="uppercase leading-loose">
      <h1 v-if="fields.titleSize.value === 'h1'" v-html="fields.title.value" />
      <h2 v-if="fields.titleSize.value === 'h2'" v-html="fields.title.value" />
      <h3 v-if="fields.titleSize.value === 'h3'" v-html="fields.title.value" />
      <h4 v-if="fields.titleSize.value === 'h4'" v-html="fields.title.value" />
    </div>

    <VoixWysiwyg
      class="prose max-w-none font-light" :class="[fields.proseSize.value]" :style="textColor"
      :field="fields.introduction"
    />

    <a
      v-if="fields.link.enabled" class="btn btn-2xs text-white bg-black relative z-10 text-center my-4"
      :href="fields.link.value.href"
      :target="fields.link.value.target"
      :class="linkClassObject"
    >{{ fields.link.value.text }}</a>
  </div>
</template>

<style lang="scss">
.prose {
  ul {
    position: relative;
    display: inline-block;
  }
}
</style>
