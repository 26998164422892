<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    containerStyles() {
      if (
        this.$voix.breakpoint.value === 'lg'
        || this.$voix.breakpoint.value === 'xl'
        || this.$voix.breakpoint.value === 'xl'
      )
        return { maxWidth: '350px' }

      return { maxWidth: '500px' }
    },

  },
  mounted() {
    defineSlice({
      description: 'Bottom-Right Copy for Slider',
      fields: { italicText: { label: 'Italic Text', type: 'text', enabled: false }, bigText: { label: 'Big Text', type: 'text', enabled: false }, description: { label: 'Description', type: 'wysiwyg', enabled: false }, link: { label: 'Link', type: 'link', enabled: false }, isRight: { label: 'Is Right?', type: 'checkbox', default: true }, upHigh: { label: 'Rides High?', type: 'checkbox', default: true } },
      name: { label: 'Bottom Right Copy', group: 'Glue' },
      templates: [{
        label: 'Bottom Right Copy',
        fields: {
          isRight: { value: true },
          upHigh: { value: true },
          bigText: { enabled: true, value: 'A PLACE LIKE NO OTHER' },
          italicText: { enabled: true, value: 'about atlantis' },
          description: { enabled: true, value: 'atlantis description' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="relative">
    <div
      class="absolute z-10  bottom-0 left-0 right-0  bg-gradient-to-tr lg:bg-gradient-to-tl from-black/100 to-black/0 h-[300px] md:h-1/2"
    />
    <div
      class="absolute z-30 px-8 md:p-0 md:w-4/5 md:w-full bottom-0 text-white"
      :class="{
        'right-0 md:text-right md:mr-24 ': fields.isRight.value,
        'ml-6 md:ml-24 left-0': !fields.isRight.value,
        'mb-3 lg:mb-20 lg:mb-48': fields.upHigh.value,
        'mb-3 lg:mb-20': !fields.upHigh.value,
      }"
      :style="containerStyles"
    >
      <div
        v-if="fields.italicText.enabled"
        class="italic text-xl lg:text-4xl tracking-wider font-light lowercase"
      >
        {{ fields.italicText.value }}
      </div>
      <div
        v-if="fields.bigText.enabled"
        class="uppercase text-xl lg:text-4xl font-sans font-light leading-tight antialiased tracking-wide"
      >
        {{ fields.bigText.value }}
      </div>
      <div
        v-if="fields.description.enabled"
        class="text-xs md:text-sm font-sans2 antialiased"
        v-html="fields.description.value"
      />
      <a
        v-if="fields.link.enabled"
        class="uppercase btn-ghost text-white btn-glue-active-ghost text-xs font-normal font-sans leading-none my-8 py-4 px-8 inline-block tracking-widest pointer-events-auto"
        :href="fields.link.value?.href"
        :target="fields.link.value.target"
      >
        {{ fields.link.value.text }}
      </a>
    </div>
  </div>
</template>
