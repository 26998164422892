
<template>
<div class="flex justify-center py-8">
  <a :href="fields.link.value?.href" :target="fields.link.value?.target"
    class="font-mmw-headline uppercase bg-mmw-teal text-white rounded-tl-xl rounded-br-xl py-4 px-6">
    {{ fields.link.value?.text }}
  </a>
</div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  data () {
      return {
      }
  },
  mounted () {
      defineSlice({"description":"Music Making Waves Link","fields":{"link":{"type":"link","label":"Link"}},"name":{"label":"Mmw Link","group":"Music Making Waves"},"templates":[{"label":"Mmw Link","fields":{"link":{"value":{"text":"VIEW SEATING CHART","target":"_blank","relationship":null,"href":"\/new-page"}}}}]})
  },
};
</script>