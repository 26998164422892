<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({ preview: 'SlicesMusicMakingWavesMmwMagazine.jpg', description: 'Music Making Waves Magazine', fields: { image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 1200, height: 800 }, sm: { width: 400, height: 400 } } }, title: { type: 'text', label: 'Title' }, description: { type: 'wysiwyg', label: 'Description' }, link: { type: 'link', label: 'Link', enabled: false }, color: { type: 'select', label: 'Color', options: { orange: 'Orange', teal: 'Teal', blue: 'Blue', black: 'Black' } }, textUnderneath: { type: 'text', label: 'Text Underneath Signup Form', default: '"Upcoming Shows Upcoming Shows Upcoming Shows"' } }, name: { label: 'Mmw Magazine', group: 'Music Making Waves' }, templates: [{ label: 'Mmw Magazine', fields: { image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/experiences\/dolphin-cay\/blue-project-sea-ray.png' }, title: { value: 'visit the blue project zone' }, description: { value: '<p>Meet some of our marine animal ambassadors face-to-face and learn more about our commitment to protecting marine life and ocean habitats<\/p>' }, link: { value: { text: 'learn more', target: '_self', relationship: null, href: '\/new-page' } }, color: { value: 'teal' } } }] })
  },
}
</script>

<template>
  <div>
    <div class="relative flex flex-col-reverse">
      <VoixMedia :field="fields.image" class="w-full h-full object-cover" />

      <svg
        viewBox="0 0 300 50" shape-rendering="geometricPrecision"
        class="absolute z-40 left-0 bottom-0 right-0 pointer-events-none"
      >
        <path
          id="mmw-text-curve" transform="matrix(1.24699 0 0 0.957918-28.898684-115.058449)"
          d="M23.174752,120.113046c0,0,27.202072,17.619083,64.649081,17.619083s62.176166-17.619083,105.982101-17.619083c36.033914,0,52.284504,5.285725,69.948187,17.619083c0,21.475551,0,46.514379,0,65.895373-121.463286,0-181.935941,0-240.579369,0c0-29.24768,0-83.514456,0-83.514456Z"
          fill="#fff" stroke-width="0.6"
        />
        <text x="0" class="font-mmw-headline font-bold uppercase text-24 translate-y-6 tracking-tight" fill="#0347D4">
          <textPath xlink:href="#mmw-text-curve">
            {{ fields.textUnderneath.value }}
          </textPath>
        </text>
      </svg>

      <div class="lg:absolute lg:left-0 lg:top-0 lg:bottom-0 flex items-center">
        <div class="bg-white p-4 lg:p-12 max-w-lg w-full">
          <div
            class="font-mmw-headline text-32 lg:text-[48px] leading-none uppercase " :class="{
              'text-mmw-teal': fields.color.value == 'teal',
              'text-mmw-orange': fields.color.value == 'orange',
              'text-mmw-blue': fields.color.value == 'blue',
              'text-black': fields.color.value == 'black',
            }"
          >
            {{ fields.title.value }}
          </div>
          <div class="font-mmw prose mt-4" v-html="fields.description.value " />
          <a
            v-if="fields.link.enabled" :href="fields.link.value?.href" :target="fields.link.value?.target"
            class="inline-block font-mmw-headline uppercase  mt-4 p-4 px-6 rounded-tl-3xl rounded-br-3xl" :class="{
              'bg-mmw-teal text-white': fields.color.value == 'teal',
              'bg-mmw-orange text-black': fields.color.value == 'orange',
              'bg-mmw-blue text-mmw-teal': fields.color.value == 'blue',
              'bg-black text-white': fields.color.value == 'black',
            }"
          >
            {{ fields.link.value?.text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
