<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({ description: 'Hides the chat', name: { label: 'Hide Five  Chat', group: 'Careers' }, templates: [{ label: 'Hide Five  Chat', fields: [] }] })
    setTimeout(() => {
      this.hideFive9Chat()
    }, 1500)
  },
  methods: {
    hideFive9Chat() {
      const five9Frame = document.querySelector('.five9-frame')
      if (five9Frame)
        document.querySelector('.five9-frame').style.display = 'none'
    },

  },
}
</script>

<template>
  <div />
</template>
