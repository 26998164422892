<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      slots: [{ name: 'default' }],
      description: 'Card with grid',
      fields: {
        title: { type: 'text', label: 'Title', enabled: false },
        sizeOfItems: { type: 'select', label: 'Size of items', options: { small: 'Small (4 Items on Desktop)', large: 'Large (3 Items on Desktop)', xl: 'Xtra Large (2 Items on Desktop)' } },
        gap: { type: 'text', label: 'Gap (in px)', default: '"50"' },
        transparentBackground: { type: 'checkbox', label: 'Transparent Background', default: false },
        showTopBorder: { type: 'checkbox', label: 'Show top border', default: true },
      },
      name: { label: 'Card With Grid', group: 'Glue' },
      templates: [{
        label: 'Card With Grid',
        fields: {
          sizeOfItems: { value: 'xl' },
          title: { value: 'Title', enabled: true },
        },
      }],
    })
  },
}
</script>

<template>
  <div>
    <div class="mx-auto small-container" :class="{ 'bg-white': !fields.transparentBackground.value }">
      <div class="py-8 px-12" :class="{ 'border-t-2 border-red-300': fields.showTopBorder.value === true }">
        <div v-if="fields.title.enabled" class="headline text-center text-red-300 text-center mt-6 mb-12">
          {{ fields.title.value }}
        </div>
        <div
          class="grid"
          :class="{
            'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4': fields.sizeOfItems.value === 'small',
            'grid-cols-1 md:grid-cols-2 lg:grid-cols-3': fields.sizeOfItems.value === 'large',
            'grid-cols-1 md:grid-cols-2': fields.sizeOfItems.value === 'xl',
          }"
          :style="{ gap: `${fields.gap.value}px` }"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
